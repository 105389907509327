<template>
    <a
        class="tour-card flex flex-col cursor-pointer drop-shadow-md fade-in"
        :href="_tour.slug"
    >
        <div class="pb-4 minM:l:pb-2 tour-img-container">
            <img
                class="tour-img object-cover"
                :class="[{ 'rounded-2xl': roundedCorners }]"
                :src="_tour.image?.contentUrl"
                :alt="_tour.image?.caption || 'Tour Bild'"
            />
        </div>
        <div class="pb-4 minM:l:pb-2 flex gap-4 items-center">
            <Tag
                v-if="_tour.additionalType"
                :iconName="config.global.categoryIcon"
                :text="_tour.additionalType"
            />
            <Tag
                v-if="_tour.state"
                :iconName="stateIcons[_tour.state]"
                :text="_tour.state.toLowerCase()"
                variant="state"
                :color="stateColors[_tour.state]"
            />
        </div>
        <h5 class="tour-name text-f5 line-clamp-2">
            {{ _tour.name }}
        </h5>
        <div
            class="info-subtags flex justify-start gap-4 minM:l:gap-2 s:gap-4 mt-auto pt-2 text-tiny-700 whitespace-nowrap overflow-hidden"
        >
            <div
                v-if="_tour.rating"
                class="flex l:px-1 difficulty-container overflow-hidden"
            >
                <div class="difficulty-dots pr-2 flex gap-1 l:gap-0.5">
                    <i
                        v-for="_solidDot in range(_tour.rating.difficulty)"
                        class="fa-solid fa-period"
                    />
                    <i
                        v-for="_lightDot in range(3 - _tour.rating.difficulty)"
                        class="fa-thin fa-period"
                    />
                </div>
                <span class="text-tiny-700 !leading-6 uppercase">
                    {{ t(getDifficultyText(_tour.rating.difficulty) as any) }}
                </span>
            </div>
            <Tag
                v-if="_tour.length"
                :iconName="config.card.distanceIcon"
                :text="getDistanceText(_tour.length)"
                color="rgba(0, 0, 0, 0.5)"
                variant="small"
            />
            <Tag
                v-if="_tour.time"
                :iconName="config.card.durationIcon"
                :text="getTimeText(_tour.time)"
                color="rgba(0, 0, 0, 0.5)"
                variant="small"
            />
        </div>
    </a>
</template>

<style lang="scss">
.tour-img {
    width: 100%;
    aspect-ratio: 0.8;
}

.tour-name {
    overflow-wrap: break-word;
}

.info-subtags {
    color: rgba(0, 0, 0, 0.5);
}

.difficulty-dots {
    font-size: 20px;
    margin-top: -4.5px;
}

.tour-card {
    @screen minL {
        .tour-img-container,
        .tour-name {
            @apply transition-all duration-300;
            @apply underline decoration-transparent;
        }

        @media (prefers-reduced-motion: no-preference) {
            &:hover {
                .tour-img-container {
                    @apply scale-105;
                }
                .tour-name {
                    @apply decoration-black;
                }
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { PropType, watch } from "vue";
import { CONFIG_OPTIONS as config, t } from "@src/globals";
import Tag from "@src/components/common/Tag.vue";
import { IndexResponse } from "@gql-types/types.generated";

const props = defineProps({
    tour: Object as PropType<IndexResponse>,
});

let _tour = props.tour as IndexResponse; // linting workaround

watch(
    () => props.tour,
    (newTour) => {
        _tour = newTour as IndexResponse;
    },
    { immediate: true },
);

const roundedCorners = config.global.roundedCorners;
const stateIcons = {
    Open: config.card.stateSuccessIcon,
    Closed: config.card.stateFailureIcon,
};
const stateColors = {
    Open: config.global.successColor,
    Closed: config.global.failureColor,
};

function range(n: number): Array<number> {
    if (n) {
        return Array.from(Array(n).keys());
    }
    return [];
}

function getDifficultyText(difficulty: number): string {
    const difficultyStrings = ["notSpecified", "easy", "medium", "difficult"];
    if (difficulty) {
        return difficultyStrings[difficulty];
    }
    return difficultyStrings[0];
}

/** @param distance Tour distance in meters
 *  @returns Formatted distance string like "10.5 KM" */
function getDistanceText(distance: number): string {
    /** Distance in KMs, rounded to 100m */
    const roundedDistance = Math.round(distance / 100) / 10;
    return `${roundedDistance} KM`;
}

/** @param time Tour Time in full minutes
 *  @returns Formatted time string like "4.5 H" */
function getTimeText(time: number): string {
    /** Time in hours, rounded to 30min */
    const roundedTime = Math.round(time / 30) / 2;
    return `${roundedTime} H`;
}
</script>
